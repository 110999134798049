import styled from 'styled-components'
import { horizontalScale, setVh } from '../../styles/utils/conversion.style'
import { font } from '../../styles/vars/font.style'
import { colors } from '../../styles/vars/colors.style'
import { mq } from '../../styles/vars/media-queries.style'
import { easings } from '../../styles/vars/easings.style'
import { animation } from '../../styles/vars/animation.style'
import { PageTransitionLinkMain } from '../PageTransitionLink/index.style'

const duration = 1

export const SpinesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
  overflow: hidden;

  ${mq.horizontal} {
    padding-left: ${horizontalScale(200)};
    padding-right: ${horizontalScale(290)};
  }
`

export const SpinesMain = styled.div`
  ${mq.belowHorizontal} {
    width: 100%;
  }

  ${mq.horizontal} {
    display: flex;
    align-items: flex-end;
    width: 100%;
    ${setVh('height', 84)}
    overflow: hidden;
  }
`

export const Spine = styled.div.attrs(props => {
  const { index, hovered, hoverIndex, inView } = props
  const flexValues = [1.27, 1.29, 0.94, 0.93]
  const transformValues = [350, 300, 200, 101]
  const flexGrow = hovered
    ? hoverIndex === index
      ? 1.5
      : 0.15
    : flexValues[index]
  const transform = inView
    ? 'translateX(0)'
    : `translateX(${transformValues[index]}%)`

  return {
    style: {
      transform,
      flexGrow,
    },
  }
})`
  position: relative;
  will-change: flex-grow, transform;
  transition: all ${duration}s ${easings.text.in};

  ${mq.belowHorizontal} {
    width: 100%;
    height: 60.7rem;
  }

  ${mq.horizontal} {
    transition: all ${duration}s ${easings.text.in},
      transform ${duration}s ${easings.text.in} ${animation.pageExitDuration}s;

    :before {
      content: '';
      position: absolute;
      top: 0;
      right: -0.1rem;
      bottom: 0;
      left: -0.1rem;
    }

    :nth-child(1):before {
      background-color: ${colors.ourStory};
    }

    :nth-child(2):before {
      background-color: ${colors.artists};
    }

    :nth-child(3) {
      z-index: 1;

      :before {
        background-color: ${colors.events};
      }
    }

    :nth-child(4):before {
      background-color: ${colors.contact};
    }

    ${PageTransitionLinkMain}:before {
      z-index: 3;
    }
  }

  ${mq.horizontal} {
    height: 100%;

    :nth-child(1) {
      height: 82.87%;
    }

    :nth-child(2) {
      height: 88.66%;
    }

    :nth-child(3) {
      height: 100%;
    }

    :nth-child(4) {
      height: 88.19%;
    }
  }
`

export const SpineMask = styled.div`
  position: absolute;
  top: 0;
  right: -0.1rem;
  bottom: 0;
  left: -0.1rem;
  overflow: hidden;
`

export const SpineContent = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  will-change: transform;

  ${Spine}:nth-child(1) & {
    background-color: ${colors.ourStory};
  }

  ${Spine}:nth-child(2) & {
    background-color: ${colors.artists};
  }

  ${Spine}:nth-child(3) & {
    background-color: ${colors.events};
  }

  ${Spine}:nth-child(4) & {
    background-color: ${colors.contact};
  }

  ${mq.horizontal} {
    z-index: 2;
  }
`

export const SpineLabel = styled.p`
  position: absolute;
  top: 10rem;
  left: 3.3rem;
  transform-origin: 0% 0%;
  transform: rotate(90deg) translateY(-100%);
  font-size: 1.6rem;
  line-height: 1;
  text-transform: uppercase;
  white-space: nowrap;

  ${mq.horizontal} {
    transform-origin: 0% 100%;
    transform: rotate(90deg);
    top: auto;
    bottom: ${horizontalScale(672)};
    left: ${horizontalScale(30)};
    font-size: ${horizontalScale(16)};
  }
`

export const SpineTitle = styled.h2`
  position: absolute;
  left: 8.4rem;
  top: 10rem;
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.extrabold};
  font-size: 32.8rem;
  line-height: 0.84;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  pointer-events: none;

  ${mq.horizontal} {
    top: auto;
    bottom: ${horizontalScale(193)};
    font-size: ${horizontalScale(600)};
    letter-spacing: 0;

    ${Spine}:nth-child(1) & {
      left: ${horizontalScale(120)};
    }

    ${Spine}:nth-child(2) & {
      left: ${horizontalScale(92)};
    }

    ${Spine}:nth-child(3) & {
      left: ${horizontalScale(60)};
    }

    ${Spine}:nth-child(4) & {
      left: ${horizontalScale(60)};
    }
  }
`

export const SpineLink = styled.p`
  position: absolute;
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.extrabold};
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;

  ${mq.belowHorizontal} {
    left: 3rem;
    bottom: 8rem;
  }

  ${mq.horizontal} {
    bottom: ${horizontalScale(180)};
    right: ${horizontalScale(32)};
    font-size: ${horizontalScale(24)};

    ${Spine}:first-child & {
      bottom: ${horizontalScale(200)};
    }
  }
`

export const SpineLinkMain = styled.span`
  display: flex;
  align-items: center;
  transition: transform 1s ${easings.text.in};

  ${mq.horizontal} {
    transform: translateY(101%);
    will-change: transform;

    ${Spine}:hover & {
      transform: translateY(0);
      transition: transform 1s ${easings.text.in} ${duration - 0.4}s;
    }
  }
`

export const SpineLinkText = styled.span`
  ${mq.belowHorizontal} {
    display: none;
  }
`

export const SpineIcon = styled.span`
  display: none;

  ${mq.horizontal} {
    display: block;
    width: ${horizontalScale(24)};
    margin-right: 0.3em;
  }
`

export const SpineImage = styled.div.attrs(({ url }) => {
  const backgroundImage = `url(${url})`

  return {
    style: {
      backgroundImage,
    },
  }
})`
  position: absolute;
  left: 8.4rem;
  right: 0;
  transform: translate3d(0, 0, 0);
  z-index: 2;
  background-color: ${colors.dark};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  ${mq.belowHorizontal} {
    bottom: 0;
    height: 23.2rem;
  }

  ${mq.horizontal} {
    left: -0.2rem;
    right: -0.2rem;

    ${({ position }) => {
      if (position === 'top') {
        return `
        top: 0;
        height: ${horizontalScale(167)};
      `
      }

      if (position === 'bottom') {
        return `
        bottom: 0;
        height: ${horizontalScale(196)};
      `
      }
    }}
  }
`
