import styled from 'styled-components'
import { horizontalScale } from '../../styles/utils/conversion.style'
import { mq } from '../../styles/vars/media-queries.style'

export const HomeDonateMain = styled.div`
  display: flex;
  flex-direction: column;

  ${mq.belowHorizontal} {
    padding-top: 18rem;
    padding-bottom: 12.6rem;
  }

  ${mq.horizontal} {
    flex-direction: row;
    align-items: flex-end;
  }
`

export const HomeDonateImageWrapper = styled.div`
  ${mq.horizontal} {
    width: ${horizontalScale(700)};
    margin-left: ${horizontalScale(108)};
  }
`
