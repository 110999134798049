import styled from 'styled-components'
import { colors } from '../../styles/vars/colors.style'
import { clamp, horizontalScale } from '../../styles/utils/conversion.style'
import { breakpoints } from '../../styles/vars/breakpoints.style'
import { mq } from '../../styles/vars/media-queries.style'
import { font } from '../../styles/vars/font.style'
import { easings } from '../../styles/vars/easings.style'

export const FeaturesMain = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;

  ${mq.belowHorizontal} {
    overflow: scroll;
  }

  ${mq.horizontal} {
    padding-right: ${horizontalScale(140)};
  }
`

export const FeaturesIcon = styled.div`
  position: absolute;
  top: 3.2rem;
  ${clamp('left', 20, 32, breakpoints.mobile, breakpoints.horizontal)}
  width: 2.3rem;
  height: 1.5rem;

  ${mq.horizontal} {
    display: none;
  }
`

export const Feature = styled.div`
  position: relative;
  width: 29rem;
  height: 54.2rem;
  transform: scaleY(0);
  transform-origin: 50% 100%;
  transition: transform 1s cubic-bezier(0.77, 0, 0.175, 1),
    height 0.25s ${easings.text.in};

  &[data-feature-index='0']:before,
  &[data-feature-index='3']:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: -0.1rem;
  }

  &[data-feature-index='0'] {
    height: 54.2rem;
    background-color: ${colors.orange};

    :before {
      background-color: ${colors.orange};
    }
  }

  &[data-feature-index='1'] {
    height: 62.2rem;
    background-color: ${colors.yellowA};
  }

  &[data-feature-index='2'] {
    height: 58.9rem;
    background-color: ${colors.red};
  }

  &[data-feature-index='3'] {
    height: 52.9rem;
    background-color: ${colors.blue};

    :before {
      background-color: ${colors.blue};
    }
  }

  &[data-feature-index='4'] {
    height: 62.2rem;
    background-color: ${colors.softRed};
  }

  [data-inview='true'] & {
    transform: scaleY(1);
  }

  ${mq.horizontal} {
    width: ${horizontalScale(290)};
    padding: 0;
    will-change: height;

    &[data-feature-index='0'] {
      height: ${horizontalScale(633)};

      :hover {
        height: ${horizontalScale(633 + 30)};
      }
    }

    &[data-feature-index='1'] {
      height: ${horizontalScale(727)};

      :hover {
        height: ${horizontalScale(727 + 30)};
      }
    }

    &[data-feature-index='2'] {
      height: ${horizontalScale(689)};

      :hover {
        height: ${horizontalScale(689 + 30)};
      }
    }

    &[data-feature-index='3'] {
      height: ${horizontalScale(618)};

      :hover {
        height: ${horizontalScale(618 + 30)};
      }
    }

    &[data-feature-index='4'] {
      height: ${horizontalScale(727)};

      :hover {
        height: ${horizontalScale(727 + 30)};
      }
    }
  }
`

export const FeatureMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  height: 100%;
  padding: 4.4rem 3.2rem 0;

  ${mq.horizontal} {
    padding: ${horizontalScale(50)} ${horizontalScale(32)};
  }

  [data-feature-index='1'] &,
  [data-feature-index='4'] & {
    justify-content: flex-end;
    padding-bottom: 4.4rem;

    ${mq.horizontal} {
      padding-bottom: ${horizontalScale(50)};
    }
  }

  a:before {
    z-index: 2;
  }
`

export const FeatureLabel = styled.p`
  position: absolute;
  top: 4.4rem;
  left: 3.2rem;
  transform-origin: 0% 0%;
  transform: rotate(90deg) translateY(-100%);
  font-size: 1.2rem;
  line-height: 1;
  text-transform: uppercase;

  ${mq.horizontal} {
    top: ${horizontalScale(50)};
    left: ${horizontalScale(32)};
    font-size: ${horizontalScale(12)};
  }
`

export const FeatureTitle = styled.h3`
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.extrabold};
  font-size: 4rem;
  line-height: 0.84;
  text-transform: uppercase;
  text-align: right;

  ${mq.belowHorizontal} {
    max-width: 18.8rem;
  }

  ${mq.horizontal} {
    padding-left: ${horizontalScale(16)};
    font-size: ${horizontalScale(48)};
  }
`

export const FeatureImage = styled.div`
  position: absolute;
  pointer-events: none;

  ${mq.belowHorizontal} {
    [data-feature-index='0'] & {
      width: 25.8rem;
    }

    [data-feature-index='2'] & {
      width: 23rem;
    }

    [data-feature-index='0'] &,
    [data-feature-index='2'] & {
      left: 3.2rem;
      bottom: 0;
    }

    [data-feature-index='3'] & {
      left: 3.2rem;
      bottom: 4.4rem;
      width: 22.9rem;
    }

    [data-feature-index='0'] &,
    [data-feature-index='2'] & {
      bottom: 0;
    }

    [data-feature-index='1'] &,
    [data-feature-index='4'] & {
      right: 0;
      top: 4.4rem;
      width: 20.6rem;
    }
  }

  ${mq.horizontal} {
    width: ${horizontalScale(226)};

    [data-feature-index='1'] &,
    [data-feature-index='4'] & {
      width: ${horizontalScale(206)};
    }

    [data-feature-index='0'] &,
    [data-feature-index='2'] & {
      bottom: 0;
    }

    [data-feature-index='3'] & {
      left: ${horizontalScale(32)};
      bottom: ${horizontalScale(50)};
    }

    [data-feature-index='1'] &,
    [data-feature-index='4'] & {
      top: ${horizontalScale(50)};
      right: -0.1rem;
    }
  }
`
