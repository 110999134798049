import React from 'react'
import PropTypes from 'prop-types'
import {
  SectionHeroMain,
  SectionHeroTitle,
  SectionHeroText,
  SectionHeroContent,
  SectionHeroIcon,
} from './index.style'
import SlideMask from '../SlideMask'
import IconArrowheadRight from '../_svgs/IconArrowheadRight'
import AnimateSplitText from '../_animation/AnimateSplitText'
import AnimateSlideIn from '../_animation/AnimateSlideIn'
import { scrollTo } from '../../utils/utils'

const SectionHero = ({ title, text, scrollToTarget, paddingBottom }) => {
  return (
    <SectionHeroMain paddingBottom={paddingBottom}>
      <SectionHeroTitle>
        <AnimateSplitText delay={0.2}>{title}</AnimateSplitText>
      </SectionHeroTitle>

      <SectionHeroContent>
        <SectionHeroText>
          <AnimateSplitText delay={0.4}>{text}</AnimateSplitText>
        </SectionHeroText>

        {scrollToTarget && (
          <SectionHeroIcon onClick={() => scrollTo(scrollToTarget)}>
            <AnimateSlideIn delay={0.6}>
              <SlideMask direction="horizontal">
                <IconArrowheadRight />
              </SlideMask>
            </AnimateSlideIn>
          </SectionHeroIcon>
        )}
      </SectionHeroContent>
    </SectionHeroMain>
  )
}

SectionHero.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  text: PropTypes.string,
  scrollToTarget: PropTypes.string,
  paddingBottom: PropTypes.number,
}

SectionHero.defaultProps = {
  paddingBottom: 218,
}

export default SectionHero
