import React from 'react'

const IconArrowheadDown = ({
  width = 26,
  height = 40,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26 40"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        fill={fill}
        d="M26 25.45v-2.575c-5.293 0-9.189 2.318-11.645 6.953V0h-2.71v29.828C9.189 25.193 5.293 22.875 0 22.875v2.576c3.6 0 6.479 1.287 8.554 3.82 2.075 2.532 3.091 6.137 3.091 10.729h2.71c0-4.592 1.059-8.197 3.134-10.73 2.075-2.532 4.912-3.82 8.511-3.82z"
      />
    </svg>
  )
}

export default IconArrowheadDown
