import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import InViewSection from '../InViewSection'
import {
  FeaturesMain,
  FeaturesIcon,
  Feature,
  FeatureMain,
  FeatureLabel,
  FeatureTitle,
  FeatureImage,
} from './index.style'
import AnimateSplitText from '../_animation/AnimateSplitText'
import AnimateSlideIn from '../_animation/AnimateSlideIn'
import AnimateImage from '../_animation/AnimateImage'
import IconArrowheadRight from '../_svgs/IconArrowheadRight'
import PageTransitionLink from '../PageTransitionLink'
import Routes from '../../routes'

const Features = ({ items }) => (
  <FeaturesMain>
    <FeaturesIcon>
      <InViewSection>
        <AnimateSlideIn delay={0.8} direction="left">
          <IconArrowheadRight responsive />
        </AnimateSlideIn>
      </InViewSection>
    </FeaturesIcon>

    {React.Children.toArray(
      items.map((item, itemIndex) => (
        <InViewSection>
          <Feature data-feature-index={itemIndex}>
            <FeatureMain>
              <PageTransitionLink
                fill
                to={`${
                  item.type === 'ContentfulArtist'
                    ? Routes.artists
                    : item.type === 'ContentfulEvent'
                    ? Routes.events
                    : Routes.partners
                }/${item.slug}`}
              >
                <FeatureTitle>
                  <AnimateSplitText delay={0.8}>{item.name}</AnimateSplitText>
                </FeatureTitle>
              </PageTransitionLink>

              <FeatureLabel>
                <AnimateSlideIn delay={0.8} direction="left">
                  {item.type === 'ContentfulEvent'
                    ? `Event  —  ${item.date}`
                    : item.type === 'ContentfulArtist'
                    ? 'Artist  —  Profile'
                    : 'Partner  —  Profile'}
                </AnimateSlideIn>
              </FeatureLabel>

              {item.featureImage && (
                <FeatureImage>
                  <AnimateImage delay={1}>
                    <GatsbyImage
                      loading="eager"
                      placeholder="dominantColor"
                      image={item.featureImage.gatsbyImageData}
                      alt={item.featureImage.title}
                    />
                  </AnimateImage>
                </FeatureImage>
              )}
            </FeatureMain>
          </Feature>
        </InViewSection>
      ))
    )}
  </FeaturesMain>
)

Features.propTypes = {
  items: PropTypes.array.isRequired,
}

export default Features
