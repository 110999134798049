import React from 'react'
import PropTypes from 'prop-types'
import Routes from '../../routes'
import AnimateSlideIn from '../_animation/AnimateSlideIn'
import AnimateSplitText from '../_animation/AnimateSplitText'
import PageTransitionLink from '../PageTransitionLink'
import IconArrowheadRight from '../_svgs/IconArrowheadRight'
import IconArrowheadDown from '../_svgs/IconArrowheadDown'
import IconInstagram from '../_svgs/IconInstagram'
import IconFacebook from '../_svgs/IconFacebook'
import IconLinkedIn from '../_svgs/IconLinkedIn'
import {
  HomeFooter,
  HomeFooterText,
  HomeIntroContent,
  HomeIntroTicketLink,
  HomeIntroCTA,
  HomeIntroMain,
  HomeIntroTicketText,
  HomeIntroCTAText,
  HomeIntroText,
  HomeIntroTitle,
  HomeScrollCTA,
} from './index.style'
import { scrollTo } from '../../utils/utils'
import SlideMask from '../SlideMask'

const HomeIntro = ({ text, ctaText, ctaUrl, introCtaText, introCtaLink }) => (
  <HomeIntroMain>
    <HomeFooter>
      <AnimateSlideIn delay={0.8}>
        <HomeFooterText>
          <p>Follow us on </p>
          <a href={Routes.instagram} aria-label="Instagram">
            <SlideMask>
              <IconInstagram width={16} height={17.14} />
            </SlideMask>
          </a>{' '}
          <a href={Routes.facebook} aria-label="Facebook">
            <SlideMask>
              <IconFacebook width={8} height={17.14} />
            </SlideMask>
          </a>{' '}
          <a href={Routes.linkedin} aria-label="LinkedIn">
            <SlideMask>
              <IconLinkedIn width={15} height={15} />
            </SlideMask>
          </a>
          {ctaText && ctaUrl && (
            <a href={ctaUrl}>
              <SlideMask>{ctaText}</SlideMask>
            </a>
          )}
        </HomeFooterText>

        <HomeFooterText>
          &copy;{new Date().getFullYear()}, THE LA ART BOX. All rights reserved{' '}
          <PageTransitionLink to={Routes.privacyPolicy}>
            <SlideMask>Privacy policy</SlideMask>
          </PageTransitionLink>
        </HomeFooterText>
      </AnimateSlideIn>
    </HomeFooter>

    <HomeIntroTitle>
      <AnimateSplitText delay={0.8} heading>
        The LA Art Box
      </AnimateSplitText>
    </HomeIntroTitle>

    <HomeIntroContent>
      <HomeIntroText>
        <AnimateSplitText delay={1.7}>{text}</AnimateSplitText>
      </HomeIntroText>

      <AnimateSlideIn delay={1.9}>
        <HomeIntroTicketLink href={introCtaLink} target="_blank">
          <HomeIntroTicketText>
            <SlideMask>{introCtaText}</SlideMask>
          </HomeIntroTicketText>

          <SlideMask direction="horizontal">
            <IconArrowheadRight />
          </SlideMask>
        </HomeIntroTicketLink>

        <HomeIntroCTA onClick={() => scrollTo('homeSpines')}>
          <HomeIntroCTAText>
            <SlideMask>Explore The LAAB</SlideMask>
          </HomeIntroCTAText>

          <SlideMask direction="horizontal">
            <IconArrowheadRight />
          </SlideMask>

          <IconArrowheadDown />
        </HomeIntroCTA>
      </AnimateSlideIn>

      <HomeScrollCTA>
        <AnimateSlideIn delay={1.9} direction="right">
          Scroll to explore
        </AnimateSlideIn>
      </HomeScrollCTA>
    </HomeIntroContent>
  </HomeIntroMain>
)

HomeIntro.propTypes = {
  text: PropTypes.string.isRequired,
  ctaText: PropTypes.string,
  ctaUrl: PropTypes.string,
  introCtaText: PropTypes.string,
  introCtaLink: PropTypes.string,
}

export default HomeIntro
