import React from 'react'
import { graphql } from 'gatsby'
import HomeIntro from '../components/HomeIntro'
import HomeFeatures from '../components/HomeFeatures'
import HomeDonate from '../components/HomeDonate'
import { ScrollWrapper } from '../components/Scroll/index.style'
import ScrollSection from '../components/ScrollSection'
import SEO from '../components/SEO'
import Spines from '../components/Spines'
import Features from '../components/Features'

const IndexPage = ({ data }) => {
  const {
    title,
    seoDescription,
    footerCtaText,
    footerCtaLink,
    introText,
    introCtaText,
    introCtaLink,
    ourStorySpineImage,
    eventsSpineImage,
    featuresText,
    features,
    donationsText,
    donationsPhoto,
  } = data.allContentfulPageHome.nodes[0]

  const ourStoryImageUrl = ourStorySpineImage.file.url
  const eventsImageUrl = eventsSpineImage.file.url

  return (
    <>
      <SEO title={title} description={seoDescription.seoDescription} />

      <ScrollWrapper>
        <ScrollSection width={1000}>
          <HomeIntro
            text={introText.introText}
            ctaText={footerCtaText}
            ctaUrl={footerCtaLink}
            introCtaText={introCtaText}
            introCtaLink={introCtaLink}
          />
        </ScrollSection>

        <ScrollSection id="homeSpines" width={1508} threshold={0.1}>
          <Spines
            ourStoryImageUrl={ourStoryImageUrl}
            eventsImageUrl={eventsImageUrl}
          />
        </ScrollSection>

        <ScrollSection width={702}>
          <HomeFeatures text={featuresText.featuresText} />
        </ScrollSection>

        <ScrollSection id="homeFeatures" trackInView={false}>
          <Features items={features} />
        </ScrollSection>

        <ScrollSection width={1284}>
          <HomeDonate
            text={donationsText.donationsText}
            image={donationsPhoto}
          />
        </ScrollSection>
      </ScrollWrapper>
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query HomePage {
    allContentfulPageHome {
      nodes {
        title
        seoDescription {
          seoDescription
        }
        footerCtaText
        footerCtaLink
        introText {
          introText
        }
        introCtaText
        introCtaLink
        ourStorySpineImage {
          file {
            url
          }
        }
        eventsSpineImage {
          file {
            url
          }
        }
        featuresText {
          featuresText
        }
        features {
          type: __typename
          ... on ContentfulArtist {
            name
            slug
            featureImage {
              title
              gatsbyImageData(quality: 80)
            }
          }
          ... on ContentfulEvent {
            name
            slug
            date(formatString: "MMM D, YYYY")
            featureImage {
              title
              gatsbyImageData(quality: 80)
            }
          }
          ... on ContentfulPartner {
            name
            slug
            featureImage {
              title
              gatsbyImageData(quality: 80)
            }
          }
        }
        donationsText {
          donationsText
        }
        donationsPhoto {
          gatsbyImageData(quality: 80)
          title
        }
      }
    }
  }
`
