import styled from 'styled-components'
import { horizontalScale, setVh } from '../../styles/utils/conversion.style'
import { font } from '../../styles/vars/font.style'
import { mq } from '../../styles/vars/media-queries.style'
import { horizontalPadding } from '../../styles/utils/functions.style'

export const HomeIntroMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${mq.belowHorizontal} {
    ${setVh('min-height', 100)}
    padding-top: 11.1rem;
    padding-bottom: 3rem;
    ${horizontalPadding()}
    justify-content: flex-end;
  }

  ${mq.horizontal} {
    padding-left: ${horizontalScale(228)};
    padding-bottom: ${horizontalScale(152)};
  }
`

export const HomeFooter = styled.div`
  display: none;

  ${mq.horizontal} {
    position: absolute;
    bottom: ${horizontalScale(35)};
    left: ${horizontalScale(35)};
    display: block;
  }
`

export const HomeFooterText = styled.div`
  display: flex;
  align-items: center;
  font-family: ${font.tertiary};
  font-size: ${horizontalScale(12)};
  line-height: 1.2;
  letter-spacing: -0.01em;

  :first-child {
    font-size: ${horizontalScale(14)};
  }

  & + & {
    margin-top: ${horizontalScale(10)};
  }

  a {
    display: inline-flex;
    margin-left: ${horizontalScale(12)};
    border-bottom: none;
  }
`

export const HomeIntroTitle = styled.h2`
  font-family: ${font.secondary};
  font-size: 13.3rem;
  font-variation-settings: ${font.weight.secondary.extrabold};
  line-height: 0.84;
  text-transform: uppercase;

  ${mq.horizontal} {
    max-width: ${horizontalScale(769)};
    font-size: ${horizontalScale(300)};
  }
`

export const HomeIntroContent = styled.div`
  ${mq.belowHorizontal} {
    position: relative;
    width: 100%;
  }

  ${mq.horizontal} {
    align-self: flex-end;
  }
`

export const HomeIntroText = styled.p`
  font-size: 3.2rem;
  font-weight: ${font.weight.light};
  line-height: 1.2;
  letter-spacing: -0.02em;

  ${mq.belowHorizontal} {
    margin-top: 0.5rem;
  }

  ${mq.horizontal} {
    max-width: ${horizontalScale(338)};
    font-size: ${horizontalScale(21)};
  }
`

export const HomeIntroTicketLink = styled.a`
  align-items: center;
  background: transparent;
  border: none;
  display: flex;

  ${mq.belowHorizontal} {
    width: 100%;
    margin-top: 4.6rem;
  }

  ${mq.horizontal} {
    margin-top: ${horizontalScale(50)};
  }
`

export const HomeIntroCTA = styled.button`
  background: transparent;
  border: none;

  ${mq.belowHorizontal} {
    width: 100%;
    margin-top: 10.6rem;

    svg:nth-of-type(1) {
      display: none;
    }
  }

  ${mq.horizontal} {
    display: flex;
    align-items: center;
    margin-top: ${horizontalScale(10)};

    svg:last-child:not(:first-child) {
      display: none;
    }
  }
`

export const HomeIntroTicketText = styled.span`
  padding-top: 0.1em;
  font-size: 5rem;
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.bold};
  line-height: 1.2;
  margin-right: 1.6rem;
  text-transform: uppercase;

  ${mq.horizontal} {
    font-size: ${horizontalScale(32)};
    margin-right: ${horizontalScale(16)};
  }
`

export const HomeIntroCTAText = styled.span`
  padding-top: 0.1em;
  font-size: ${horizontalScale(32)};
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.bold};
  line-height: 1.2;
  text-transform: uppercase;

  ${mq.belowHorizontal} {
    display: none;
  }

  ${mq.horizontal} {
    margin-right: ${horizontalScale(22)};
  }
`

export const HomeScrollCTA = styled.p`
  position: absolute;
  bottom: 0;
  right: 0;
  transform-origin: 100% 0%;
  transform: translateY(100%) rotate(90deg);
  font-size: 1.3rem;
  line-height: 1;
  text-transform: uppercase;

  ${mq.horizontal} {
    display: none;
  }
`
