import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import SectionHero from '../SectionHero'
import { HomeDonateImageWrapper, HomeDonateMain } from './index.style'
import AnimateImage from '../_animation/AnimateImage'

const HomeDonate = ({ text, image }) => (
  <HomeDonateMain>
    <SectionHero title="Art of giving" text={text} />
    <HomeDonateImageWrapper>
      <AnimateImage>
        <GatsbyImage
          loading="eager"
          placeholder="dominantColor"
          image={image.gatsbyImageData}
          alt={image.title}
        />
      </AnimateImage>
    </HomeDonateImageWrapper>
  </HomeDonateMain>
)

HomeDonate.propTypes = {
  text: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
}

export default HomeDonate
