import React from 'react'
import PropTypes from 'prop-types'
import SectionHero from '../SectionHero'
import { HomeFeaturesMain } from './index.style'

const HomeFeatures = ({ text }) => (
  <HomeFeaturesMain>
    <SectionHero
      title="Stories that find you"
      text={text}
      scrollToTarget="homeFeatures"
      paddingBottom={100}
    />
  </HomeFeaturesMain>
)

HomeFeatures.propTypes = {
  text: PropTypes.string.isRequired,
}

export default HomeFeatures
