import styled from 'styled-components'
import { horizontalScale } from '../../styles/utils/conversion.style'
import { font } from '../../styles/vars/font.style'
import { mq } from '../../styles/vars/media-queries.style'
import { HomeFeaturesMain } from '../HomeFeatures/index.style'
import { horizontalPadding } from '../../styles/utils/functions.style'

export const SectionHeroMain = styled.div`
  ${mq.belowHorizontal} {
    padding-top: 5.8rem;
    padding-bottom: 3.2rem;
    ${horizontalPadding()}
  }

  ${mq.horizontal} {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-bottom: ${({ paddingBottom }) => horizontalScale(paddingBottom)};
  }
`

export const SectionHeroTitle = styled.h2`
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.bold};
  font-size: 11rem;
  line-height: 0.84;
  text-transform: uppercase;

  ${mq.horizontal} {
    font-size: ${horizontalScale(180)};
    text-align: right;
  }
`

export const SectionHeroContent = styled.div`
  margin-top: 2.8rem;

  ${mq.horizontal} {
    margin-top: ${horizontalScale(54)};
  }
`

export const SectionHeroText = styled.p`
  max-width: 13em;
  font-size: 1.6rem;
  font-weight: ${font.weight.light};
  line-height: 1.2;
  letter-spacing: -0.02em;

  ${mq.horizontal} {
    max-width: ${horizontalScale(330)};
    font-size: ${horizontalScale(21)};
    letter-spacing: 0;
  }
`

export const SectionHeroIcon = styled.button`
  margin-top: 7rem;
  border: none;
  background-color: transparent;

  ${mq.belowHorizontal} {
    ${HomeFeaturesMain} & {
      display: none;
    }
  }

  ${mq.horizontal} {
    margin-top: ${horizontalScale(42)};
  }
`
