import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import gsap from 'gsap'
import Routes from '../../routes'
import PageTransitionLink from '../PageTransitionLink'
import {
  Spine,
  SpineContent,
  SpineIcon,
  SpineImage,
  SpineLabel,
  SpineLink,
  SpineLinkMain,
  SpineLinkText,
  SpineMask,
  SpinesMain,
  SpinesWrapper,
  SpineTitle,
} from './index.style'
import IconArrowheadRight from '../_svgs/IconArrowheadRight'
import { breakpoints } from '../../styles/vars/breakpoints.style'

const Spines = ({ inView, ourStoryImageUrl, eventsImageUrl }) => {
  const spineRefs = useRef([])
  const [hovered, setHovered] = useState(false)
  const [hoverIndex, setHoverIndex] = useState(null)

  const handleMouseEnter = index => {
    setHoverIndex(index)
    setHovered(true)

    if (window.innerWidth >= breakpoints.horizontal) {
      gsap.to(spineRefs.current[index], {
        xPercent: -30,
        duration: 0.6,
        ease: 'power3.inOut',
      })
    }
  }

  const handleMouseLeave = () => {
    if (window.innerWidth >= breakpoints.horizontal) {
      gsap.to(spineRefs.current[hoverIndex], {
        xPercent: 0,
        duration: 0.6,
        ease: 'power3.inOut',
      })
    }

    setHoverIndex(null)
    setHovered(false)
  }

  return (
    <SpinesWrapper>
      <SpinesMain>
        <Spine
          index={0}
          hovered={hovered}
          hoverIndex={hoverIndex}
          inView={inView}
          onMouseEnter={() => handleMouseEnter(0)}
          onMouseLeave={handleMouseLeave}
        >
          <SpineMask>
            <SpineContent ref={ref => (spineRefs.current[0] = ref)}>
              <SpineLabel>Our story</SpineLabel>
              <SpineTitle>LAAB</SpineTitle>
            </SpineContent>
          </SpineMask>

          <PageTransitionLink to={Routes.ourStory} fill>
            <SpineLink>
              <SpineLinkMain>
                <SpineIcon>
                  <IconArrowheadRight responsive />
                </SpineIcon>
                <SpineLinkText>Learn more</SpineLinkText>
              </SpineLinkMain>
            </SpineLink>
          </PageTransitionLink>

          <SpineImage position="bottom" url={ourStoryImageUrl} />
        </Spine>

        <Spine
          index={1}
          hovered={hovered}
          hoverIndex={hoverIndex}
          inView={inView}
          onMouseEnter={() => handleMouseEnter(1)}
          onMouseLeave={handleMouseLeave}
        >
          <SpineMask>
            <SpineContent ref={ref => (spineRefs.current[1] = ref)}>
              <SpineLabel>Artists</SpineLabel>
              <SpineTitle>Artists</SpineTitle>
            </SpineContent>
          </SpineMask>

          <PageTransitionLink to={Routes.artists} fill>
            <SpineLink>
              <SpineLinkMain>
                <SpineIcon>
                  <IconArrowheadRight responsive />
                </SpineIcon>
                <SpineLinkText>Learn more</SpineLinkText>
              </SpineLinkMain>
            </SpineLink>
          </PageTransitionLink>
        </Spine>

        <Spine
          index={2}
          hovered={hovered}
          hoverIndex={hoverIndex}
          inView={inView}
          onMouseEnter={() => handleMouseEnter(2)}
          onMouseLeave={handleMouseLeave}
        >
          <SpineMask>
            <SpineContent ref={ref => (spineRefs.current[2] = ref)}>
              <SpineLabel>Events</SpineLabel>
              <SpineTitle>Events</SpineTitle>
            </SpineContent>
          </SpineMask>

          <PageTransitionLink to={Routes.events} fill>
            <SpineLink>
              <SpineLinkMain>
                <SpineIcon>
                  <IconArrowheadRight responsive />
                </SpineIcon>
                <SpineLinkText>Learn more</SpineLinkText>
              </SpineLinkMain>
            </SpineLink>
          </PageTransitionLink>
          <SpineImage position="top" url={eventsImageUrl} />
        </Spine>

        <Spine
          index={3}
          hovered={hovered}
          hoverIndex={hoverIndex}
          inView={inView}
          onMouseEnter={() => handleMouseEnter(3)}
          onMouseLeave={handleMouseLeave}
        >
          <SpineMask>
            <SpineContent ref={ref => (spineRefs.current[3] = ref)}>
              <SpineLabel>Contact</SpineLabel>
              <SpineTitle>Contact</SpineTitle>
            </SpineContent>
          </SpineMask>

          <PageTransitionLink to={Routes.contact} fill>
            <SpineLink>
              <SpineLinkMain>
                <SpineIcon>
                  <IconArrowheadRight responsive />
                </SpineIcon>
                <SpineLinkText>Learn more</SpineLinkText>
              </SpineLinkMain>
            </SpineLink>
          </PageTransitionLink>
        </Spine>
      </SpinesMain>
    </SpinesWrapper>
  )
}

Spines.propTypes = {
  inView: PropTypes.bool,
  ourStoryImageUrl: PropTypes.string,
  eventsImageUrl: PropTypes.string,
}

export default Spines
